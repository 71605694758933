import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, NavLink, useParams } from "react-router-dom";
import LogoImg from "../../assets/images/iamsamarth-Logo-Final.svg";
import DashbordRoutes from "../../routes/dashboard";
import { ToastContainer, toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../app/service/users.service";
import { GET_ACCESS_PERMISSIONS } from "../../app/service/access-permissions.service";

interface Props {
  location?: any;
}

export const DashboardView = (props: Props) => {
  let url: string | undefined = props.location?.pathname.split("/")[2];
  const [username, setUsername] = useState<any>();
  const [userRole, setUserRole] = useState<any>();
  const [roleName, setRoleName] = useState<any>();
  const [canShowStudentView, setCanShowStudentView] = useState(false);
  const [canSubMenuActive, setCanSubMenuActive] = useState(false);
  const [canSubMenuActive1, setCanSubMenuActive1] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState<any[]>([]);
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [urlPath, setUrlPath] = useState<any>();
  const [showMenuList, setShowMenuList] = useState(true);
  let objectList: any = [];
  const history = useHistory();
  let id = props.location?.pathname.split("/")[4];
  let schoolCode = props.location?.pathname.split("/")[6];
  const [logotTooltip, setLogoutTooltip] = useState<boolean>(false);
  useEffect(() => {
    const userName = localStorage.getItem("user_name");
    const userRole = localStorage.getItem("user_role");
    const roleName = localStorage.getItem("role_name");
    const user_code = localStorage.getItem("user_code");
    const urlpath = props.location?.pathname.split("/")[2];
    if (userRole === "STUDENT" ||
      userRole === "PARENT") {
      setShowMenuList(false);
      setCanShowStudentView(true);
    }

    if (userName === null && userRole === null && roleName === null) {
      history.push("/");
    }

    setUsername(userName);
    setUserRole(userRole);
    setRoleName(roleName);
    setUrlPath(urlpath);

    if (
      userRole === "PRINCIPAL" ||
      userRole === "VICE_PRINCIPAL" ||
      userRole === "SCHOOL_ADMIN" ||
      userRole === "TEACHER" ||
      userRole === "STUDENT" ||
      userRole === "PARENT"
    ) {

    } else {
    }
    getPermissions(userRole);

  }, []);

  const getPermissions = (roleCode: any) => {
    objectList = [];
    GET_ACCESS_PERMISSIONS(roleCode).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
        } else {
          const data: any = res;
          objectList = data;
          if (res.length > 0) {
            filterData(data);
          }
          getSidebarMenuList(roleCode);
        }
      }
    });
  };

  const filterData = (originalData: any) => {
    let finalData = [];
    let parentData = [];
    let childData = [];
    for (let index = 0; index < originalData.length; index++) {
      let element: any = originalData[index];
      if (element.object_code.includes("-")) {
        childData.push(element);
      } else {
        element.child = [];
        element.canShowChildren = false;
        parentData.push(element);
      }
    }
    for (let index = 0; index < childData.length; index++) {
      const element = childData[index];
      if (element.object_code.includes("-")) {
        let splitTitle = element.object_code.split("-");
        for (let i = 0; i < parentData.length; i++) {
          let ele: any = parentData[i];
          if (ele.object_code == splitTitle[0]) {
            ele.child.push(element);
          }
        }
      } else {
        parentData.push(element);
      }
    }

    finalData = parentData.filter((el) => el.p_read === 1 || el.p_write);
    if (finalData.length > 0 || userRole === "SUPER_ADMIN") {
      setCanShowStudentView(false);
    } else {
      setCanShowStudentView(true);
    }
  };

  function onClickHome() {
    setCanShowPopup(true);
  }

  function getUserDetails(user_code: any) {
    GET_USER_USER_CODE(user_code).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
        } else {
          const data = res.rows[0];
          setUserInfo(data);
        }
      }
    });
  }

  const handleclose = () => {
    setCanShowPopup(false);
  };

  const handleSubmit = () => {
    setCanShowPopup(false);
    history.push("/home");
    localStorage.clear();
  };

  const isCheckReports = () => {
    return "REPORTS-CAS-REPORT";
  };

  function getSidebarMenuList(userRole: any) {


    const list = [
      {
        name: "Dashboard",
        route: "/dashboard/view",
        isShow: (true && userRole === "SUPER_ADMIN") || isShowMenuItem("DASHBOARD"),
      },
      {
        name: "Departments",
        route: "/dashboard/department",
        isShow: isShowMenuItem("DEPARTMENTS"),
      },
      {
        name: "MSB Users",
        route: "/dashboard/users",
        isShow: isShowMenuItem("MSB"),
      },
      {
        name: "Institutes",
        route: "/dashboard/institute/list",
        isShow: isShowMenuItem("INSTITUTES"),
      },
      {
        name: "Schools",
        route: "/dashboard/school/list",
        isShow: isShowMenuItem("SCHOOLS"),
      },
      {
        name: "Individual Students",
        route: "/dashboard/individual-student/list",
        isShow: isShowMenuItem("INDIVIDUAL"),
      },
      {
        name: "Modules",
        route: "/dashboard/module/list",
        isShow: isShowMenuItem("MODULES"),
      },
      {
        name: "Sponsor",
        route: "/dashboard/sponsor",
        isShow: (true && userRole === "SUPER_ADMIN")
      },
      {
        name: "Promotions",
        route: "/dashboard/promotion/list",
        isShow: (true && userRole === "SUPER_ADMIN")
      },
      // {
      //   name: "Special Discount",
      //   route: "/dashboard/special-discount/list",
      //   isShow: (true && userRole === "SUPER_ADMIN")
      // },
      {
        name: "Membership",
        route: "/dashboard/subscription/info/list",
        isShow: (true && userRole === "SUPER_ADMIN")
      },
      {
        name: "Roles",
        route: "/dashboard/roles",
        isShow: (true && userRole === "SUPER_ADMIN") || isShowMenuItem("ROLES"),
      },
      {
        name: "Access Objects",
        route: "/dashboard/access-objects",
        isShow: (true && userRole === "SUPER_ADMIN") || isShowMenuItem("ACCESS"),
      },
      {
        name: "Reports",
        route: isShowRouting(),
        isShow: isShowMenuItem(isShowReportsMenu()),
        submenuList: [
          {
            subname: "CAS Report",
            subRoute: "/dashboard/cas-report/school/list",
            isShow: isShowMenuItem("REPORTS-CAS-REPORT"),
          },
          {
            subname: "Membership Report",
            subRoute: "/dashboard/subscription-report/school/list",
            isShow: isShowMenuItem("REPORTS-SUBSCRIPTION-REPORT"),
          },
          {
            subname: "Maths Report",
            subRoute: "/dashboard/maths-report/school/list",
            isShow: isShowMenuItem("REPORTS-MATHS-REPORT"),
          },
          {
            subname: "Sponsor Report",
            subRoute: "/dashboard/sponsor-report/list",
            isShow: (true && userRole === "SUPER_ADMIN")
          },
          {
            subname: "Coupon Report",
            subRoute: "/dashboard/coupon-report/list",
            isShow: (true && userRole === "SUPER_ADMIN")
          },
        ],
      },

      {
        name: "Payment",
        route: "/dashboard/payments",
        isShow: isShowMenuItem("PAYMENT"),
      }
    ];

    setSidebarMenu(list);
  }

  const isShowRouting = () => {
    const filterData = [];

    for (let i = 0; i < objectList.length; i++) {
      if ("REPORTS-CAS-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        objectList[i].routePath = "/dashboard/cas-report/school/list";
        filterData.push(objectList[i]);
      } else if ("REPORTS-SUBSCRIPTION-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        objectList[i].routePath = "/dashboard/subscription-report/school/list";
        filterData.push(objectList[i]);
      } else if ("REPORTS-MATHS-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        objectList[i].routePath = "/dashboard/maths-report/school/list";
        filterData.push(objectList[i]);
      }
    }

    if (filterData.length > 0) {
      if (filterData.length === 3) {
        return "/dashboard/cas-report/school/list";
      } else {
        return filterData[0].routePath;
      }
    }

  };

  const isShowReportsMenu = () => {
    const filterData = [];
    for (let i = 0; i < objectList.length; i++) {
      if ("REPORTS-CAS-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        filterData.push(objectList[i]);
      } else if ("REPORTS-SUBSCRIPTION-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        filterData.push(objectList[i]);
      } else if ("REPORTS-MATHS-REPORT" === objectList[i].object_code && (objectList[i].p_read === 1 || objectList[i].p_write === 1)) {
        filterData.push(objectList[i]);

      }
    }


    if (filterData.length > 0) {
      if (filterData.length === 3) {
        return "REPORTS-CAS-REPORT";
      } else {
        return filterData[0].object_code;
      }
    }

  };

  const isShowMenuItem = (object: string) => {

    const findObjet = objectList.find((data: any) => {
      if (object === data.object_code && (data.p_read === 1 || data.p_write === 1)) {
        return true;
      } else {
        return false;
      }
    });
    if (findObjet) {
      return true;
    } else {
      return false;
    }
  };

  function handleSubmenus(item: any) {
  }

  function handlemenus(item: any) {
    if (item.name === "Reports") {
      setCanSubMenuActive(!canSubMenuActive);
    } else {
      setCanSubMenuActive(false);
    }
  }

  return (
    <div>
      <div
        className={`modal fade ${canShowPopup ? "show" : "false"}`}
        style={{ display: canShowPopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Are You Sure Want To Logout
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleclose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary cursor-pointer"
                onClick={handleSubmit}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleclose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="inside-bg">
        <header className="navbar navbar-dark sticky-top flex-md-nowrap px-3 p-lg-0">
          <a className="navbar-brand me-0">
            <img src={LogoImg} className="logo" alt="loading-pic" />
          </a>
          <button
            className="navbar-toggler position-absolute d-lg-none text-black collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMen u"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i
              className="fa fa-bars fa-2x toggle-btn"
              data-toggle="collapse"
              data-target="#menu-content"
            ></i>
          </button>
          <div className="position-relative">
            <div className="d-none d-lg-flex align-items-center">
              <span className="text-end user-name pe-3">
                {username}
                <br />
                <small>{roleName}</small>
              </span>
              <a
                className="mx-3 logout-icon cursor-pointer" onMouseEnter={() => setLogoutTooltip(true)}
                onMouseLeave={() => setLogoutTooltip(false)}
                onClick={() => {
                  onClickHome();
                }}
              >
                Sign out
              </a>

            </div>
            {logotTooltip && <p className="mb-0 position-absolute bg-white  border border-secondary px-1 f14 rounded-2 " style={{ right: "5px" }}>Logout</p>}

          </div>
        </header>
        <div className="container-fluid">
          <div className="row">
            {showMenuList &&
              <div
                className={
                  !canShowStudentView ? "nav-side-menu d-lg-block col-lg-2  " : "d-none "
                }
              >

                <div className="menu-list">
                  {!canShowStudentView && (
                    <ul id="menu-content" className="menu-content collapse out">
                      {sidebarMenu.map((name: any, i: number) => (
                        <React.Fragment key={i}>
                          {name.isShow && (
                            <li>
                              <Link
                                className={`nav-link ${(name.name === "Dashboard" && url == "view") ||
                                  (name.name === "Departments" &&
                                    url == "department") ||
                                  (name.name === "MSB Users" && url == "users") ||
                                  (name.name === "Institutes" &&
                                    url == "institute") ||
                                  (name.name === "Schools" && url == "school") ||
                                  (name.name === "Individual Students" &&
                                    url == "individual-student") ||
                                  (name.name === "Payment" &&
                                    url == "payments") ||
                                  (name.name === "Roles" && url == "roles") ||
                                  (name.name === "Access Objects" &&
                                    url == "access-objects") || (name.name === "Membership" && url == "subscription") || (name.name === "Sponsor" && url == "sponsor") ||
                                  (name.name === "Promotions" && url == "promotion") ||
                                  // (name.name === "Special Discount" && url == "special-discount") ||
                                  (name.name === "Modules" && url == "module")
                                  ? "active"
                                  : ""
                                  }`}
                                to={name.route}
                                onClick={() => handlemenus(name)}>
                                {name.name}
                                {name.name === "Reports" && (
                                  <span
                                    className="arrow"
                                    onClick={() => handlemenus(name)}
                                  ></span>
                                )}
                              </Link>
                              {name.submenuList && (
                                <ul
                                  className={
                                    url === "subscription-report" ||
                                      url === "cas-report" ||
                                      url === "maths-report" || url === "sponsor-report" || url === "coupon-report"
                                      ? "sub-menu collapse reports-show"
                                      : "sub-menu collapse"
                                  }
                                >
                                  {canSubMenuActive && name.submenuList ? name.submenuList.map(
                                    (name1: any, j: number) => (
                                      <React.Fragment key={j}>
                                        {name1.isShow && <li onClick={() => handleSubmenus(name1)}>
                                          <Link
                                            className={`nav-link ${(name1.subname === "CAS Report" &&
                                              url === "cas-report") ||
                                              (name1.subname ===
                                                "Membership Report" &&
                                                url ===
                                                "subscription-report") ||
                                              (name1.subname ===
                                                "Maths Report" &&
                                                url === "maths-report") || (name1.subname === "Sponsor Report" && url === "sponsor-report") || (name1.subname === "Coupon Report" && url === "coupon-report")
                                              ? "active"
                                              : ""
                                              }`}
                                            to={name1.subRoute}
                                          >
                                            {name1.subname}
                                          </Link>
                                        </li>}
                                      </React.Fragment>
                                    )
                                  )
                                    : ""}
                                </ul>
                              )}
                            </li>
                          )
                          }
                        </React.Fragment>
                      ))}
                    </ul>
                  )}
                </div>
              </div>}
            <div className={` content-body ${!canShowStudentView ? "content-waper " : ""}`}>
              <DashbordRoutes />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
