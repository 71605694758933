import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_PARENT_BY_STUDENT, GET_USER_USER_CODE } from "../../../app/service/users.service";
import appConfig from '../../../config/constant';
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import SUBJECTCOMPLETE from '../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import FATHERIMG from '../../../institute-dashboard-assets/images/father-icon.svg'
import MOTHERIMG from '../../../institute-dashboard-assets/images/mother-icon.svg'
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import { useHistory } from "react-router-dom";
import { GET_MNS_STUDENT_STATUS } from "../../../app/service/mns-service";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../../app/service/user-subscription";
import { format } from 'date-fns';


function StudentDashboardList() {
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const UserCode = localStorage.getItem("user_code");
    const [parentList, setParentList] = useState<any>([]);
    const userRole = localStorage.getItem("user_role");
    const [moduleData, setModuleData] = useState<any>([]);
    const history = useHistory();
    const [freeTrail, setFreeTrail] = useState<boolean>(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const userDetails = await getUserDetails();
            await Promise.all([
                getParents(userDetails),
                getModulesByUserCode(UserCode, userRole, userDetails)
            ]);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getUserDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(UserCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.rows[0];
                if (data?.account_type === "TRIAL" && data?.trial_start_dt && data?.trial_end_dt) {
                    const trialStartDate = new Date(data?.trial_start_dt);
                    const trialEndDate = new Date(data?.trial_end_dt);
                    const currentDate = new Date();
                    const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
                    if (currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate)) {
                        setFreeTrail(true);
                    }
                    else if (data?.subscription_status !== "SUBSCRIBED") {
                        handlePayNow();
                    }
                } else if (data?.subscription_status !== "SUBSCRIBED") {
                    handlePayNow();
                }
                else {
                    setFreeTrail(false);
                }
                setStudentInfo(data);
                return data;
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        }
    };

    const getParents = async (data: any) => {
        if (data?.guardian) {
            try {
                const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
                const res: any = await GET_PARENT_BY_STUDENT(guardian);
                if (res.status === 'fail') {
                    toast.error(res.message);
                    throw new Error(res.message);
                } else {
                    const data = res.rows;
                    setParentList(data);
                }
            } catch (error) {
                console.error('Error fetching parent details:', error);
                throw error;
            }
        }
    };

    const getModulesByUserCode = async (userCode: any, userRole: any, studentInfo?: any) => {
        try {
            const res: any = await GET_MODULES_BY_USER_CODES(userCode);
            if (res.status === 'fail') {
                toast.error(res.message);
                throw new Error(res.message);
            } else {
                let data = res;
                if (userRole !== "PARENT") {
                    data = res.filter((el: any) => el.module_code !== 'CAS-PARENT');
                }
                setModuleData(data);
            }
        } catch (error) {
            console.error('Error fetching modules:', error);
            throw error;
        }
    };

    function openTest(data: any) {
        if (data.module_code === "MATHS") {
            localStorage.removeItem('setTimer');
            localStorage.setItem("backword_key", "false");
            getMNSStudentStatus(data);
        }
        else if (data?.module_code === "CAS") {
            history.push(`/student-dashboard/list/cas-test-instructions/${data.user_details['user_code']}/${data.module_code}`)
        }
        else if (data?.module_code === "ABHYAAS") {
            history.push(`/student-dashboard/list/abhyas/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
        } else if (data?.module_code === "PARIKSHA") {
            // history.push(`/student-dashboard/list/pariksha/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
            history.push(`/student-dashboard/list/pariksha/test-initiative/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);

        }
    }

    function getMNSStudentStatus(data: any) {

        let payload = {
            module_uuid: data.uuid,
            school_code: studentInfo?.institute_school_code,
            user_code: data.user_details['user_code']
        }
        setLoading(true);
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        history.push(`/student-dashboard/list/mns/view/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    } else {
                        history.push(`/student-dashboard/list/mns/test-level/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    }
                }
            }
        });
    }

    function onClickViewReports(item: any) {
        if (item.module_code === "CAS") {
            history.push(`/student-dashboard/list/reports/student/${item.uuid}/${item.user_details.user_code}`)
        }

        if (item.module_code === "MATHS") {
            // history.push(`/math-report-pdf/${item.user_details.user_code}`);

            history.push(`/student-dashboard/list/maths/reports/student/${item.user_details.user_code}`);

        }
    }

    const getModuelFreeTrial = (moduleCode: string) => {
        const modulesFreeTrial: string = studentInfo?.modules;
        if (moduleCode && modulesFreeTrial && freeTrail) {
            const modulesArray = modulesFreeTrial?.split(',').map((item: any) => item?.trim());
            return modulesArray?.includes(moduleCode);
        } else {
            return false;
        }
    }
    const handlePayNow = () => {
        setCanShowPaymentPopup(true);
    }
    const cancelPayment = () => {
        setCanShowPaymentPopup(false);

    }
    const onPayments = () => {
        history.push(`/student-dashboard/list/${studentInfo?.user_code}/payments`)
    }

    const capitalizeWords = (name: string): string => {
        return name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const getSubscriptionStatus = (status: string) => {
        switch (status) {
            case "SUBSCRIBED":
                return <li className="breadcrumb-item text-subc">Subscribed</li>;
            case "NOT SUBSCRIBED":
                return <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>;
            case "SUBSCRIPTION EXPIRED":
                return <li className="breadcrumb-item text-notsubc">Subscription Expired</li>;
            case "DORMANT":
                return <li className="breadcrumb-item text-notsubc">Dormant</li>;
            default:
                return <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>;
        }
    }
    const getMemberShipStatus = () => {
        if (studentInfo?.subscription_status === "SUBSCRIBED" || studentInfo?.subscription_status === "DORMANT" || studentInfo?.subscription_status === "SUBSCRIPTION EXPIRED") {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="payment_modal-dialog_popup " role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={cancelPayment} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1 d-flex justify-content-center'>
                                    <div className="text-start ">
                                        {getMemberShipStatus() ?
                                            <div>
                                                <h5 className='f20 payment_mem_text'>To continue to access all the  <br />  initiatives on "I am Samarth"<br />  app, please renew your annual <br />membership.</h5>
                                            </div>
                                            :
                                            <div>
                                                <h5 className='f20 payment_mem_text'>To  access all the  initiatives on <br /> "I am Samarth" app, you need<br />to register for our annual<br /> membership.  </h5>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn payment_user_btn f18 px-4 px-md-5 payment_mem_text py-2' onClick={onPayments}>{getMemberShipStatus() ? "Renew Membership" : "Get Membership"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item"><span>Dashboard</span></li>
                    </ol>
                </nav>
                <div className="border-bottom pb-3">
                    <div className="d-md-flex sc-profile-details">
                        <div className="mb-3 mb-md-0">
                            <div className="sc-profile-img py-0">
                                <img
                                    src={studentInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${studentInfo.profile_img.charAt(0) === "/" ? studentInfo.profile_img.substring(1) : studentInfo.profile_img}` : STUDENTIMG}
                                    alt=""
                                    className="pr-logo"
                                />
                            </div>
                        </div>
                        <div className="sc-profile-text ps-md-4">
                            <ol className="breadcrumb border-0 mb-2 p-0">{getSubscriptionStatus(studentInfo?.subscription_status)}<span className=" f16 ms-2 cursor-pointer subscribe_btn_user" onClick={handlePayNow}>{getMemberShipStatus() ? "Renew Now" : "Subscribe"}</span></ol>
                            <h2 className="mt-0 mb-2">{studentInfo?.name && capitalizeWords(studentInfo?.name)}</h2>
                            <p className="m-0 sc-profile-contact">
                                <span>{studentInfo?.mobile_number}</span> {studentInfo?.email_id && <span> | </span>} <span>{studentInfo?.email_id} </span>
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                <span>{studentInfo?.state}</span> {studentInfo?.district && <span> | </span>} <span>{studentInfo?.district}</span>{studentInfo?.city_village && <span> | </span>}
                                <span>{studentInfo?.city_village}</span>
                            </p>
                        </div>
                    </div>
                </div>
                {studentInfo?.guardian && <h3 className="mt-4 mb-3 section-hd">Parent Details</h3>}
                <div className={`${studentInfo?.guardian && "border-bottom"} pb-3 mb-4`}>
                    <div className="row">
                        {parentList?.map((data: any, i: number) => {
                            return (
                                <div className="col-md-6" key={i}>
                                    <div className="d-flex sc-profile-details small mb-4">
                                        <div className="sc-profile-img pt-0">
                                            {data?.gender === "female" ? <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : MOTHERIMG}
                                                alt=""
                                                className="pr-image"
                                            /> : <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : FATHERIMG}
                                                alt=""
                                                className="pr-image"
                                            />}
                                        </div>
                                        <div className="sc-profile-text ps-4">
                                            <p className="sc-profile-deg mb-1">{data.gender === "female" ? <span>Mother</span> : <span>Father</span>}</p>
                                            <h2 className="m-0">{data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)}</h2>
                                            <p className="m-0 sc-profile-contact">
                                                <span>{data?.mobile_number}</span> {data?.email_id && <span> | </span>} <span>{data?.email_id} </span>
                                            </p>
                                            <p className="m-0 sc-profile-addrs">
                                                <span>{data?.state}</span> {data?.district && <span> | </span>} <span>{data?.district}</span>{(data?.city_village && data?.district) && <span> | </span>}
                                                <span>{data?.city_village}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
                <div className="row">
                    <div className="module_list_cards d-flex justify-content-center justify-content-md-start">
                        {moduleData?.map((data: any, i: number) => {
                            return (
                                <div key={i} className={`module_card_view position-relative ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "cardTwo"} ${data?.module_code === "ABHYAAS" && "cardThree"}`}>
                                    {data.module_code === "MATHS" && <img className=" module_logo_img" src={MNSLOGO} alt="" />}
                                    {data.module_code === "ABHYAAS" && <img className="  module_logo_img" src={ABHYAASLOGO} alt="" />}
                                    {data.module_code === "PARIKSHA" && <img className=" module_logo_img" src={PARIKSHALOGO} alt="" />}
                                    <div>
                                        {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                        {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                        {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                        {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                        {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                        {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                        {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                    </div>
                                    <p className="mb-2 ">
                                        {/* Payment - <label >{(freeTrail && getModuelFreeTrial(data?.module_code)) ? <span className="free_trial_btn f14">Free Trial</span> : (data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label> */}
                                        Payment - <label > {studentInfo?.subscription_status === "SUBSCRIBED" ? <span className="text-completed"> Paid</span> : (freeTrail && getModuelFreeTrial(data?.module_code)) ? <span className="free_trial_btn f14">Free Trial</span> : (studentInfo?.subscription_status !== "SUBSCRIBED" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} </label>

                                    </p>
                                    {((studentInfo?.subscription_status === "SUBSCRIBED") || (freeTrail && getModuelFreeTrial(data?.module_code))) ? <p className="m-0">
                                        Status - <label>{(data.module_status === "inprogress" || data.module_status === null) && <span className="text-inprogess">In Progress <span onClick={() => openTest(data)} className="btn btn-continue ms-3">
                                            Continue test</span></span>}{data.module_status === "completed" && <span className="text-completed"> Completed</span>}</label>
                                    </p> : <p className="m-0">
                                        {/* <span className="btn btn-pay" onClick={() => onPayments()}>Pay Now</span> */}
                                    </p>
                                    }
                                    {data?.module_status === "completed" && <img
                                        src={SUBJECTCOMPLETE}
                                        alt=""
                                        className="module-completed-logo"
                                    />}
                                    <div className="d-flex mt-2">
                                        {/* {studentInfo?.subscription_status === "SUBSCRIBED" && <p className="mb-0 me-5 pe-3"><span className="btn btn-continue mt-2 " id={`${data?.module_code}_VIEW`} onClick={() => onPayments()}> Renewal </span></p>} */}
                                        {(data?.module_status === "completed" || (data?.module_code === "MATHS" && studentInfo?.subscription_status === "SUBSCRIBED") || (data?.module_code === "MATHS" && freeTrail && getModuelFreeTrial(data?.module_code))) && <p className="m-0 "> <span className="btn btn-continue mt-2 " id={`${data?.module_code}_VIEW`} onClick={() => onClickViewReports(data)}> View Report </span></p>}
                                    </div>
                                </div>
                            )
                        })}
                        {moduleData?.length === 0 && <p className="mt-4 f14">Modules are not Assigned</p>}
                    </div>
                </div>
            </div>}

        </div>
    )

}
export default StudentDashboardList;