// import React from "react";
import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../components/data-table";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { PromoCodeListDataGridCols } from "./data-grid-cols";
import { toast } from "react-toastify";
import { DELETE_PROMO_CODES_DATA, GET_PROMOTIONS_BY_UUID, GET_PROMOTIONS_LIST, GET_PROMO_CODES_BY_PROMOTION_UUID_PAGE_NUMBER, UPDATE_PROMO_CODES } from "../../../../app/service/promotions";
import { Breadcum } from "../../../../components/breadcum";




function PromoCodeList() {
    let { Uuid } = useParams<{ Uuid: any }>();
    const promoUuid = Uuid;
    const [loading, setLoading] = useState(false);
    const [promoCodeList, setPromoCodeList] = useState<any>([]);
    const history = useHistory();
    const [activePage, setActivePage] = useState<any>();
    const [promotionData, setPromotionData] = useState<any>({});
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [couponPopup, setCouponPopup] = useState(false);
    const [couponDetails, setCouponDetails] = useState<any>({});
    const [couponRules, setCouponRules] = useState<any>({});
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Promotions",
            value: "Promotions",
            routerLink: "/dashboard/promotion/list",
            isActive: false,
        },
        {
            label: "Promotion Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);

    useEffect(() => {
        for (let j = 0; j < PromoCodeListDataGridCols.length; j++) {
            if (PromoCodeListDataGridCols[j].title === 'Actions') {
                PromoCodeListDataGridCols[j].isEdit = false;
                PromoCodeListDataGridCols[j].canShowColumn = false;
            }
        }
        fecthAllData();
        // getPromotionData();
        // getPromoCodeList();

    }, []);

    const fecthAllData = async () => {
        setLoading(true);
        try {
            await getPromotionData();
            await getPromoCodeList();
            setLoading(false);
        } catch {

        }
    }
    const getPromotionData = async () => {
        try {
            const res: any = await GET_PROMOTIONS_BY_UUID(promoUuid);
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.rows[0];
                getBreadcumList(data)
                if (data.end_at) {
                    data.end_at = moment(data.end_at.split("T")[0]).format('DD-MM-yyyy');
                }
                if (data.start_at) {
                    data.start_at = moment(data.start_at.split("T")[0]).format('DD-MM-yyyy');
                }
                setPromotionData(data);

            }
        } catch (error) {

        }


    }

    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Promotion Name",
            value: data.name,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }
    const getPromoCodeList = async () => {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        try {
            const res: any = await GET_PROMO_CODES_BY_PROMOTION_UUID_PAGE_NUMBER(promoUuid, activePageNumber);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        element.modified_dt = moment(element.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        if (element.status == 1 || element.status == 0) {
                            for (let j = 0; j < PromoCodeListDataGridCols.length; j++) {
                                if (PromoCodeListDataGridCols[j].title === 'Actions') {
                                    PromoCodeListDataGridCols[j].isEdit = true;
                                    PromoCodeListDataGridCols[j].canShowColumn = true;
                                }
                            }
                        }
                    });
                    setPromoCodeList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        } catch (error) {

        }

    }
    const onEditPromoCode = (data: any) => {
        history.push(`/dashboard/promotion/promocode/form/${promoUuid}/${data.uuid}?create=1`);
    }
    const onDeletePromoCode = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_PROMO_CODES_DATA(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Promotion Deleted Successfully");
                getPromoCodeList();
            }
        });

    }
    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/promotion/promocode/list/${promoUuid}?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PROMO_CODES_BY_PROMOTION_UUID_PAGE_NUMBER(promoUuid, pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        element.modified_dt = moment(element.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        if (element.status == 1 || element.status == 0) {
                            for (let j = 0; j < PromoCodeListDataGridCols.length; j++) {
                                if (PromoCodeListDataGridCols[j].title === 'Actions') {
                                    PromoCodeListDataGridCols[j].isEdit = true;
                                    PromoCodeListDataGridCols[j].canShowColumn = true;
                                }
                            }
                        }
                    });
                    setPromoCodeList(data);
                    setLoading(false);
                }
            }
        });

    }
    const getCodeDetails = (data: any) => {
        setCouponPopup(true);
        setCouponDetails(data);
        let preparedObject = JSON.parse(data?.algorithm)
        setCouponRules(preparedObject[0])

    };
    const handleCouponClose = () => {
        setCouponPopup(false);
    };
    const onUpdateItem = async (item: any, value?: number) => {
        const payLoad = {
            "send_sms": value ? value : 0,
            "send_email": value ? value : 0,
            "send_push_notifications": value ? value : 0,
            "status": value ? value : 0,

        }
        try {
            const res: any = await UPDATE_PROMO_CODES(item?.uuid, payLoad);
            if (res.status === 'fail') {
                toast.error(res?.message);
            } else {
                await getPromoCodeList();
            }
        } catch (error) {

        }
    }
    return (
        <div>
            <div
                className={`modal fade ${couponPopup ? "show" : "false"}`}
                style={{ display: couponPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Coupon Details
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCouponClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body  parent-model">
                            {Object.keys(couponDetails).length > 0 && <div className=" ">
                                <div className='table-responsive mar-t-20 custom-scroll mb-4'>
                                    <table className='table table  fixed-table-header mb-0'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>Coupon Code</th>
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Min Amount</th>}
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Max Amount</th>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Min Students</th>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Max Students</th>}
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}> Discount Amount</th>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}> Discount Percentage</th>}
                                                <th style={{ width: "100px" }}>Promotion Name</th>
                                                <th style={{ width: "100px" }}>Promotion Type</th>
                                                <th style={{ width: "100px" }}>Discount Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{couponDetails?.promo_code}</td>
                                                <td> {couponRules?.min}</td>
                                                <td>{couponRules?.max}</td>
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <td>{couponRules?.discount_amount?.toFixed()}</td>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <td>{couponRules?.discount_percentage?.toFixed()} %</td>}
                                                <td> {promotionData?.name}</td>
                                                <td> {promotionData?.promotion_type}</td>
                                                <td> {promotionData?.discount_type?.replace(/_/g, " ")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleCouponClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="mt-3">
                        <Breadcum breadcumList={breadcumInfo} />
                    </div>
                    <div className=" mb-3">
                        <h5 className="border-bottom pb-3">Promotion Details</h5>
                        <div className='table-responsive mar-t-20 custom-scroll my-3'>
                            <table className='table   fixed-table-header mb-0'>
                                <thead>
                                    <tr>
                                        <th style={{ width: "100px" }}>Promotion Name</th>
                                        <th style={{ width: '100px' }}>Promotion Type </th>
                                        <th style={{ width: '100px' }}>Discount Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>{promotionData?.name}</td>
                                        <td> {promotionData?.promotion_type}</td>
                                        <td>{promotionData?.discount_type?.replace(/_/g, " ")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className=" mb-3 d-flex justify-content-between border-bottom pb-3">
                        {/* <div><h5>Promo Codes</h5></div> */}
                        <h5 className="mb-0 ">Coupon Codes</h5>
                        <div className="">
                            <Link to={`/dashboard/promotion/promocode/form/${promoUuid}/0?create=0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                        </div>
                    </div>

                    <DataTable TableCols={PromoCodeListDataGridCols} tableData={promoCodeList} editInfo={onEditPromoCode} deleteInfo={onDeletePromoCode} onChange={getCodeDetails}
                        activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray} onUpdateItem={onUpdateItem}></DataTable>
                </div>
            }

        </div>
    )

}
export default PromoCodeList;